// Hard coded date for mvp test https://mece.atlassian.net/jira/software/projects/B2C/boards/12?selectedIssue=B2C-86
export const POSTCODE_BUYERS = {
	"10115": 48,
	"10117": 72,
	"10119": 81,
	"10178": 37,
	"10179": 88,
	"10243": 89,
	"10245": 48,
	"10247": 41,
	"10249": 47,
	"10315": 89,
	"10317": 75,
	"10318": 48,
	"10319": 63,
	"10365": 43,
	"10367": 41,
	"10369": 44,
	"10405": 88,
	"10407": 87,
	"10409": 61,
	"10435": 59,
	"10437": 60,
	"10439": 25,
	"10551": 28,
	"10553": 78,
	"10555": 67,
	"10557": 90,
	"10559": 53,
	"10585": 44,
	"10587": 36,
	"10589": 26,
	"10623": 57,
	"10625": 85,
	"10627": 88,
	"10629": 67,
	"10707": 55,
	"10709": 55,
	"10711": 72,
	"10713": 50,
	"10715": 72,
	"10717": 55,
	"10719": 52,
	"10777": 77,
	"10779": 40,
	"10781": 25,
	"10783": 36,
	"10785": 41,
	"10787": 48,
	"10789": 47,
	"10823": 85,
	"10825": 77,
	"10827": 67,
	"10829": 84,
	"10961": 70,
	"10963": 62,
	"10965": 79,
	"10967": 71,
	"10969": 39,
	"10997": 72,
	"10999": 88,
	"12043": 73,
	"12045": 39,
	"12047": 79,
	"12049": 48,
	"12051": 77,
	"12053": 37,
	"12055": 90,
	"12057": 28,
	"12059": 37,
	"12099": 33,
	"12101": 80,
	"12103": 41,
	"12105": 79,
	"12107": 31,
	"12109": 37,
	"12157": 59,
	"12159": 89,
	"12161": 43,
	"12163": 27,
	"12165": 63,
	"12167": 41,
	"12169": 68,
	"12203": 52,
	"12205": 62,
	"12207": 42,
	"12209": 41,
	"12247": 62,
	"12249": 87,
	"12277": 70,
	"12279": 77,
	"12305": 61,
	"12307": 75,
	"12309": 34,
	"12347": 46,
	"12349": 88,
	"12351": 29,
	"12353": 51,
	"12355": 87,
	"12357": 57,
	"12359": 80,
	"12435": 46,
	"12437": 69,
	"12439": 83,
	"12459": 78,
	"12487": 32,
	"12489": 69,
	"12524": 90,
	"12526": 75,
	"12527": 33,
	"12555": 32,
	"12557": 32,
	"12559": 40,
	"12587": 72,
	"12589": 57,
	"12619": 79,
	"12621": 70,
	"12623": 41,
	"12627": 31,
	"12629": 86,
	"12679": 50,
	"12681": 37,
	"12683": 79,
	"12685": 69,
	"12687": 46,
	"12689": 84,
	"13051": 65,
	"13053": 34,
	"13055": 59,
	"13057": 52,
	"13059": 37,
	"13086": 79,
	"13088": 25,
	"13089": 33,
	"13125": 71,
	"13127": 74,
	"13129": 36,
	"13156": 81,
	"13158": 81,
	"13159": 33,
	"13187": 85,
	"13189": 30,
	"13347": 83,
	"13349": 62,
	"13351": 42,
	"13353": 56,
	"13355": 71,
	"13357": 41,
	"13359": 52,
	"13403": 89,
	"13405": 50,
	"13407": 74,
	"13409": 72,
	"13435": 50,
	"13437": 88,
	"13439": 84,
	"13465": 50,
	"13467": 51,
	"13469": 41,
	"13503": 79,
	"13505": 44,
	"13507": 28,
	"13509": 82,
	"13581": 86,
	"13583": 44,
	"13585": 35,
	"13587": 88,
	"13589": 29,
	"13591": 73,
	"13593": 53,
	"13595": 75,
	"13597": 26,
	"13599": 86,
	"13627": 40,
	"13629": 44,
	"14050": 51,
	"14052": 27,
	"14053": 86,
	"14055": 64,
	"14057": 31,
	"14059": 26,
	"14089": 50,
	"14109": 89,
	"14129": 86,
	"14163": 78,
	"14165": 26,
	"14167": 78,
	"14169": 80,
	"14193": 84,
	"14195": 62,
	"14197": 80,
	"14199": 40,
	"16321": 29,
	"15378": 67,
	"15345": 75,
	"15366": 35,
	"15562": 67,
	"15566": 57,
	"15754": 81,
};
